//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IndentedContent from '../Partials/IndentedContent.vue';
import TextBlock from '../Partials/TextBlock.vue';
import ImageCopyright from '../Partials/ImageCopyright.vue';

export default {
    name: 'TextImageOffset',
    components: {ImageCopyright, TextBlock, IndentedContent},
    props: ['data'],
    data() {
        return {
            mediaPath: this.$config.pimcoreUrl,
        };
    },
    computed: {
        leftImage() {
            return this.$mapImageData(this.data?.left_image?.image);
        },
        rightImage() {
            return this.$mapImageData(this.data?.right_image?.image);
        },
    },
};
